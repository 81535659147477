import React, { memo } from 'react';

import { CircleCheckedIcon } from '../../shared/ui/Icons';
import { MockImg2025, Logo244 } from '../../assets/images';
import { Typography } from 'antd';
const PostcardSectionComponent = () => {
	const style = {
		backgroundImage: `url(${MockImg2025})`
	};
	return (
		<section className='relative -mt-[--lp-header-height] min-h-screen pt-32 flex flex-col items-center overflow-hidden bg-white'>
			<div className='max-w-[1200px]'>
				<div className='lg:hidden flex flex-col items-center justify-center max-w-[482px] mx-auto text-center px-6'>
					<Typography.Title level={2} className='!font-bold'>
						До новых встреч в 2025
					</Typography.Title>
					<Typography.Paragraph className='!font-medium'>
						Новый год позади, но наше сообщество креативных умов продолжает расти и развиваться! Хотя на сайте больше
						нельзя генерировать новые изображения, мы приглашаем вас вдохновиться другими возможностями GPTZATOR.
					</Typography.Paragraph>
				</div>
				<div className='w-full flex flex-col items-center mb-20 lg:mb-32 mt-10 lg:mt-20 px-6'>
					<div
						style={style}
						className='relative w-full h-[480px] bg-primary-bg rounded-full lg:rounded-[500px] bg-no-repeat bg-[center_bottom] lg:bg-left-bottom flex items-center justify-end px-[155px]'
					>
						<img src={Logo244} alt='Logo' className='absolute right-[152px] -top-[60px] hidden lg:block' />
						<div className='hidden lg:flex flex-col max-w-[482px]'>
							<Typography.Title level={1} className='!font-bold'>
								До новых встреч в 2025
							</Typography.Title>
							<Typography.Paragraph className='!font-medium text-base'>
								Новый год позади, но наше сообщество креативных умов продолжает расти и развиваться! Хотя на сайте
								больше нельзя генерировать новые изображения, мы приглашаем вас вдохновиться другими возможностями
								GPTZATOR.
							</Typography.Paragraph>
						</div>
					</div>
				</div>
				<p className='text-3xl font-bold text-center mb-16'>
					В 2025 году у нас большие планы на GPTZATOR —
					<br />
					ИИ-помощника, созданного группой IT-компаний Lad
				</p>
				<div className='flex flex-col items-center lg:items-start lg:grid grid-cols-3 gap-7 mb-32 px-12'>
					<div className='max-w-[600px] flex items-start justify-start lg:flex-col gap-7'>
						<CircleCheckedIcon className='text-5xl' />
						<p className='text-xl'>
							GPTZATOR - это российское решение в области использования нейросетей. Он работает без использования VPN.
						</p>
					</div>
					<div className='max-w-[600px] flex items-start lg:flex-col gap-7'>
						<CircleCheckedIcon className='text-5xl' />
						<p className='text-xl'>
							С его помощью можно не только создавать открытки и тексты, но и сделать процессы в работе, бизнесе,
							образовании гораздо эффективнее.
						</p>
					</div>
					<div className='max-w-[600px] flex items-start lg:flex-col gap-7'>
						<CircleCheckedIcon className='text-5xl' />
						<p className='text-xl'>
							ИИ-помощник всегда найдет нужную информацию как из внешних источников, так и в данных компании (например
							из 1С:Торговля), даст рекомендации, поможет принять нужное решение и т.д.
						</p>
					</div>
				</div>
			</div>
		</section>
	);
};

export const MockSection = memo(PostcardSectionComponent);
