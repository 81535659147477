import { PrimaryLogo } from 'shared/lib/getIcon';
import { useSearchParams } from 'react-router-dom';
import { logEvent, useIntersectionObserver } from 'shared/lib';
import { useCallback, useEffect, useRef } from 'react';
import { Footer } from '../../widgets/Footer';
import { MockSection } from '../../widgets/MockSection';
const DEFAULT_IMG_ID = '12';

export const Landing = () => {
	const [params, setURLParams] = useSearchParams();
	const postcardId = params.get('id');
	const sectionRef = useRef<HTMLDivElement>(null);
	useIntersectionObserver({
		targetRef: sectionRef,
		onIntersect: () => logEvent('Пролистал до открытки')
	});

	useEffect(() => {
		if (postcardId) {
			logEvent('Открыл страницу', {
				id: postcardId
			});
		} else {
			logEvent('Открыл страницу');
		}
	}, [postcardId]);

	const animationCompleteHandler = useCallback(() => {
		if (sectionRef.current) {
			sectionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	}, [sectionRef]);

	return (
		<>
			<div className='sticky -top-1 z-20 bg-black text-white w-full flex items-center justify-center min-h-[--lp-header-height]'>
				<div className='max-w-full w-[1200px] flex justify-between px-5 -mb-[1px]'>
					<a href='https://gptzator.ru/' target='_blank' rel='noreferrer' className='flex items-center gap-x-2 '>
						<div className='min-w-[76px] flex items-center justify-center'>
							<PrimaryLogo className='w-full h-full' />
						</div>
						<span className='text-xl text-white font-bold min-h-[52px] flex items-center'>GPTZATOR</span>
						<span className='text-xl text-white border-l border-solid border-white border-y-0 border-r-0 min-h-[52px] hidden lg:flex items-center px-4 ml-2 whitespace-nowrap'>
							Группа IT-компаний Lad
						</span>
					</a>

					<div className='flex items-center gap-x-10 text-xl font-medium text-white'></div>
				</div>
			</div>
			<main>
				<MockSection />
				<Footer />
			</main>
		</>
	);
};
