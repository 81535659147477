interface IGtagEvent {
	[key: string]: string | number | boolean | undefined;
}

const gtag = (...args: any[]) => {
	if (typeof window !== 'undefined' && (window as any).gtag) {
		(window as any).gtag(...args);
	} else {
		console.warn('Google Tag Manager is not initialized');
	}
};

export const logEvent = (eventName: string, eventParams?: IGtagEvent): void => {
	gtag('event', eventName, eventParams);
};

export default gtag;
