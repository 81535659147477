import { useEffect, RefObject, useState } from 'react';

interface UseIntersectionObserverProps {
	targetRef: RefObject<Element>;
	onIntersect: () => void;
	options?: IntersectionObserverInit;
}

export const useIntersectionObserver = ({
	targetRef,
	onIntersect,
	options = { threshold: 0 }
}: UseIntersectionObserverProps) => {
	const [hasIntersected, setHasIntersected] = useState(false);

	useEffect(() => {
		if (hasIntersected || !targetRef.current) return;

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					onIntersect();
					setHasIntersected(true);
					observer.unobserve(entry.target);
				}
			});
		}, options);

		observer.observe(targetRef.current);

		return () => observer.disconnect();
	}, [targetRef, onIntersect, options, hasIntersected]);
};
