import React from 'react';
import { FooterBg } from '../../assets/images';
import { Button } from 'antd';

export const Footer = () => {
	const footerBgImage = {
		backgroundImage: `url(${FooterBg})`
	};
	return (
		<div style={footerBgImage} className='py-32 bg-dark bg-cover bg-no-repeat w-full flex items-center justify-center'>
			<div className='max-w-[1200px] flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between gap-28 mx-auto px-5'>
				<div className='flex flex-col items-center justify-center'>
					<p className='text-white text-[24px] font-bold max-w-[600px] text-center mb-6'>
						Еще больше о возможностях GPTZATOR расскажем на вебинаре
					</p>
					<Button
						href='https://pruffme.com/landing/u3188462/tmp1734947248'
						target='_blank'
						type='primary'
						size='large'
						className='min-h-[56px] min-w-[350px] font-bold uppercase text-xl'
					>
						Зарегистрироваться
					</Button>
				</div>
			</div>
		</div>
	);
};
